var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-overview", attrs: { fluid: "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c("h1", [_vm._v("Locatie toevoegen")]),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "", "py-0": "" } },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _setup.currentStep,
                callback: function ($$v) {
                  _setup.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 1, step: "1" } },
                [_vm._v(" Laden gegevens ")]
              ),
              _c("v-stepper-content", { attrs: { step: "1" } }, [
                _c(
                  "span",
                  { class: { loadingDots: !_setup.masterDataLoaded } },
                  [_vm._v("Gegevens worden geladen")]
                ),
              ]),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 2, step: "2" } },
                [_vm._v(" Doel opgeven ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs2" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-layout",
                                { staticClass: "form-group checkbox-group" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.pandGoals,
                                        expression: "pandGoals",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "PPM",
                                      value: "PPM",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_setup.pandGoals)
                                        ? _vm._i(_setup.pandGoals, "PPM") > -1
                                        : _setup.pandGoals,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _setup.pandGoals,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "PPM",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_setup.pandGoals = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_setup.pandGoals = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _setup.pandGoals = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "PPM" } }, [
                                    _vm._v("Paris Proof Monitor"),
                                  ]),
                                  _c("br"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _setup.pandGoals,
                                        expression: "pandGoals",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "EN",
                                      value: "EN",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_setup.pandGoals)
                                        ? _vm._i(_setup.pandGoals, "EN") > -1
                                        : _setup.pandGoals,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _setup.pandGoals,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "EN",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_setup.pandGoals = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_setup.pandGoals = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _setup.pandGoals = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", { attrs: { for: "EN" } }, [
                                    _vm._v("Energy Navigator"),
                                  ]),
                                  !_setup.isAtLeastOneChecked
                                    ? _c("div", [
                                        _vm._v(
                                          "Selecteer tenminste één optie."
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Party", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Partij",
                                            items: _setup.parties,
                                            "item-text": "name",
                                            "item-value": "reference",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.partyReference,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "partyReference",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.partyReference",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Rol", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Rol",
                                            items: _setup.partyRoles,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.partyRole,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "partyRole",
                                                $$v
                                              )
                                            },
                                            expression: "location.partyRole",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Installatiegroep",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Installatiegroep",
                                            items: _setup.installationGroups,
                                            "item-text": "ref",
                                            "item-value": "id",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location
                                                .installationGroupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "installationGroupId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installationGroupId",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Bron bouwkundige gegevens",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Bron bouwkundige gegevens",
                                            items:
                                              _setup.constructionSourceTypes,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.buildingGroupKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "buildingGroupKey",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.buildingGroupKey",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Maatregelengroep",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Maatregelengroep",
                                            items: _setup.measureGroups,
                                            "item-text": "reference",
                                            "item-value": "id",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.ecmGroupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "ecmGroupId",
                                                $$v
                                              )
                                            },
                                            expression: "location.ecmGroupId",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_setup.isAtLeastOneChecked,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 3, step: "3" } },
                [_vm._v(" Zoek locatie in BAG ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs3" },
                    [
                      _c(
                        "v-row",
                        [_c("v-col", [_vm._v(" Kies Sublocatie adres(sen) ")])],
                        1
                      ),
                      _c(_setup.BagSearch, {
                        on: { selected: _setup.selectedBag },
                      }),
                      _c("v-row", [_c("v-col", [_vm._v(" Adres ")])], 1),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Referentie",
                                  rules: "required|max:50",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Referentie",
                                            counter: 50,
                                            "error-messages": errors,
                                            success: valid,
                                          },
                                          model: {
                                            value:
                                              _setup.location.custReference,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "custReference",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.custReference",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Omschrijving",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Omschrijving",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.details.descr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.details,
                                                "descr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.details.descr",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  label: "Straatnaam",
                                },
                                model: {
                                  value: _setup.location.address.street,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.location.address,
                                      "street",
                                      $$v
                                    )
                                  },
                                  expression: "location.address.street",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Huisnummer", rules: "numeric" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            "hide-details": "auto",
                                            label: "Huisnummer",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.hn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "hn",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.hn",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Huisletter", rules: "max:1" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Huisletter",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.hl,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "hl",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.hl",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  label: "Toevoeging",
                                },
                                model: {
                                  value: _setup.location.address.hna,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.location.address,
                                      "hna",
                                      $$v
                                    )
                                  },
                                  expression: "location.address.hna",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Postcode",
                                  rules: "postalcode",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Postcode",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.pc,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "pc",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.pc",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  label: "Woonplaats",
                                },
                                model: {
                                  value: _setup.location.address.city,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.location.address,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression: "location.address.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                  label: "Land",
                                },
                                model: {
                                  value: _setup.location.address.country,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.location.address,
                                      "country",
                                      $$v
                                    )
                                  },
                                  expression: "location.address.country",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Lengtegraad",
                                  rules: "required|double|between:-180,180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Lengtegraad (lon)",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.lon,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "lon",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.lon",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Breedtegraad",
                                  rules: "required|double|between:-180,180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Breedtegraad (lat)",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.lat,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "lat",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.lat",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Type", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Type",
                                            items:
                                              _setup.addressableObjectTypes,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _setup.location.address.typ,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "typ",
                                                $$v
                                              )
                                            },
                                            expression: "location.address.typ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Adres type",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Adres Type",
                                            items: _setup.addressTypes,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.address.adrTyp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "adrTyp",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.address.adrTyp",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Bron type", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Bron Type",
                                            readonly: "",
                                            items: _setup.addressSourceTypes,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.address.srcTyp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.address,
                                                "srcTyp",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.address.srcTyp",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                ref: "consDat",
                                attrs: {
                                  name: "ConstructionDate",
                                  rules: "date_between|required",
                                  mode: _setup.checkDateValid,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(_setup.DateInput, {
                                          attrs: {
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Bouwdatum",
                                            errors: errors,
                                            rules: "required",
                                          },
                                          model: {
                                            value:
                                              _setup.location.details.consDat,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.details,
                                                "consDat",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.details.consDat",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                ref: "valFrom",
                                attrs: {
                                  name: "Geldig vanaf",
                                  rules: "date_between|required",
                                  mode: _setup.checkDateValid,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(_setup.DateInput, {
                                          attrs: {
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Geldig vanaf",
                                            errors: errors,
                                            rules: "required",
                                          },
                                          model: {
                                            value: _setup.location.valFrom,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "valFrom",
                                                $$v
                                              )
                                            },
                                            expression: "location.valFrom",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                ref: "valTill",
                                attrs: {
                                  name: "Geldig tot",
                                  rules: `date_between|date_after:${_setup.location.valFrom}`,
                                  mode: _setup.checkDateValid,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c(_setup.DateInput, {
                                          attrs: {
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Geldig tot",
                                            errors: errors,
                                          },
                                          model: {
                                            value: _setup.location.valTill,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location,
                                                "valTill",
                                                $$v
                                              )
                                            },
                                            expression: "location.valTill",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_setup.isBagSelected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 4, step: "4" } },
                [_vm._v(" Aanvullende gegevens ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs4" },
                    [
                      _setup.pandGoals.length > 0 &&
                      _setup.pandGoals.includes("PPM")
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _vm._v(" Paris Proof Monitor "),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Type",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Type",
                                                      items:
                                                        _setup.locationTypes,
                                                      "item-text":
                                                        "description",
                                                      "item-value": "key",
                                                      "error-messages": errors,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.location.details
                                                          .typ,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.location
                                                            .details,
                                                          "typ",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "location.details.typ",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3713249873
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Functie",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Functie",
                                                      items:
                                                        _setup.ecmFunctions,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      "error-messages": errors,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.location.details
                                                          .ecmFuncId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.location
                                                            .details,
                                                          "ecmFuncId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "location.details.ecmFuncId",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3500321949
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "WEii Bouwbesluit",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "WEii Bouwbesluit",
                                                      items:
                                                        _setup.weiiBldDecrees,
                                                      "item-text": "name",
                                                      "item-value": "key",
                                                      "error-messages": errors,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.location
                                                          .weiiBldDecreeKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.location,
                                                          "weiiBldDecreeKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "location.weiiBldDecreeKey",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2353550630
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "WEii Klasse",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "WEii Klasse",
                                                      items:
                                                        _setup.weiiBldCategories,
                                                      "item-text": "name",
                                                      "item-value": "key",
                                                      "error-messages": errors,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.location.details
                                                          .weiiBldCatKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.location
                                                            .details,
                                                          "weiiBldCatKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "location.details.weiiBldCatKey",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2294512357
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "EED",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "EED",
                                                      items: _setup.eedTypes,
                                                      "item-text":
                                                        "description",
                                                      "item-value": "key",
                                                      "error-messages": errors,
                                                    },
                                                    model: {
                                                      value:
                                                        _setup.location.details
                                                          .eedTyp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _setup.location
                                                            .details,
                                                          "eedTyp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "location.details.eedTyp",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3153459792
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gebruiksoppervlakte",
                                          rules:
                                            "required|numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      attrs: {
                                                        type: "number",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        "hide-details": "auto",
                                                        label:
                                                          "Gebruiksoppervlakte (GO)",
                                                        value:
                                                          _setup.location
                                                            .details.go,
                                                        "error-messages":
                                                          errors,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.location.details.go =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("m²")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2526836211
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        style: [
                          _setup.pandGoals.length === 2
                            ? { height: "50px" }
                            : { height: "0px" },
                        ],
                      }),
                      _setup.pandGoals.length > 0 &&
                      _setup.pandGoals.includes("EN")
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_vm._v(" Energy Navigator ")])],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bouwdatum",
                                          rules:
                                            "required|date_between:1500-01-01,2050-12-31",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(_setup.DateInput, {
                                                    attrs: {
                                                      value:
                                                        _setup.location.details
                                                          .consDat,
                                                      outlined: "",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Bouwdatum",
                                                      min: new Date(1500, 0, 1),
                                                      errors: errors,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.location.details.consDat =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3214919339
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Dakoppervlakte",
                                          rules:
                                            "required|numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      attrs: {
                                                        type: "number",
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        label:
                                                          "Dakoppervlakte (DO)",
                                                        value:
                                                          _setup.location
                                                            .details.do,
                                                        "error-messages":
                                                          errors,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.location.details.do =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("m²")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          13622888
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        style: [
                          _setup.pandGoals.length > 0
                            ? { height: "50px" }
                            : { height: "0px" },
                        ],
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _vm._v(" Gedeelde gegevens (PPM én EN) "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Bruto vloeroppervlakte",
                                      rules:
                                        "required|numeric|between:0,9999999",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              {
                                                attrs: {
                                                  type: "number",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  "persistent-placeholder": "",
                                                  label:
                                                    "Bruto vloeroppervlakte (BVO)",
                                                  value:
                                                    _setup.location.details.bvo,
                                                  "error-messages": errors,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _setup.location.details.bvo =
                                                      $event !== ""
                                                        ? $event
                                                        : undefined
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("m²")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 5, step: "5" } },
                [_vm._v(" Energielabel ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(_setup.EnergyLabelSearch, {
                            attrs: {
                              postalCode: _setup.location.address.pc,
                              houseNumber: _setup.location.address.hn,
                              houseNumberAddition: _setup.location.address.hna,
                            },
                            on: { selected: _setup.selectedEnergyLabel },
                          }),
                        ],
                        1
                      ),
                      _setup.energyLabelSelected
                        ? _c(
                            "v-row",
                            [_c("v-col", [_vm._v("Geselecteerd label:")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Label", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Label",
                                            items: _setup.energyLabels,
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.label.energyLabel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.label,
                                                "energyLabel",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.label.energyLabel",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Bron", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Bron",
                                            items: _setup.labelSources,
                                            "item-text": "description",
                                            "item-value": "key",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.label.sourceKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.label,
                                                "sourceKey",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.label.sourceKey",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "EnergyIndex",
                                  rules: "decimal:4|between:0,9",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            type: "number",
                                            label: "Energie-index",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.label.energyIndex,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.label,
                                                "energyIndex",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.label.energyIndex",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 6, step: "6" } },
                [_vm._v(" Installatiegegevens ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "6" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs6" },
                    [
                      _c("v-row", [_c("v-col")], 1),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Verwarming",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Verwarming",
                                            items: _setup.heating,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation
                                                .heating,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "heating",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.heating",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Verlichting",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Verlichting",
                                            items: _setup.lighting,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation
                                                .lighting,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "lighting",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.lighting",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Koeling",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Koeling",
                                            items: _setup.cooling,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation
                                                .cooling,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "cooling",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.cooling",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Bedekkingsgraad PV",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Bedekkingsgraad PV",
                                            items: _setup.pv,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation.pv,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "pv",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.pv",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Ventilatie",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Ventilatie",
                                            items: _setup.ventilation,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation
                                                .ventilation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "ventilation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.ventilation",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Warm water",
                                  rules: _setup.validationRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details": "auto",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            label: "Warm water",
                                            items: _setup.domesticHotWater,
                                            "item-text": "name",
                                            "item-value": "value",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value:
                                              _setup.location.installation
                                                .domesticHotWater,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.location.installation,
                                                "domesticHotWater",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "location.installation.domesticHotWater",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 7, step: "7" } },
                [_vm._v(" Constructiegegevens ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "7" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs7" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Soort")]),
                              _vm._l(_setup.kinds, function (item, index) {
                                return _c(
                                  "div",
                                  { key: item.key },
                                  [
                                    index > 0 ? _c("v-divider") : _vm._e(),
                                    _c(_setup.ConstructionKindInput, {
                                      attrs: {
                                        kindKey: item.key,
                                        description: item.description,
                                        boundaryTypes: _setup.boundaryTypes,
                                        loadedKinds: _setup.loadedKinds,
                                        insulationCategories:
                                          _setup.insulationCategories,
                                        partData: _setup.getPartData(item.key),
                                        onlyCategories: true,
                                        editMode: true,
                                      },
                                      on: { "update-data": _setup.updatePart },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 8, step: "8" } },
                [_vm._v(" Energiedata ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "8" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs8" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.RegisterInput, {
                                attrs: {
                                  registerTypes: _setup.registerTypes,
                                  registerEnergyTypes:
                                    _setup.registerEnergyTypes,
                                  registerUnitTypes: _setup.registerUnitTypes,
                                },
                                on: {
                                  "return-register-data":
                                    _setup.updateRegisters,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.nextStep()
                                    },
                                  },
                                },
                                [_vm._v("Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "normal" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 9, step: "9" } },
                [_vm._v(" Controle ingevoerde gegevens ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "9" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs9" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "boxed" },
                        [
                          _c("header", [
                            _vm._v("Locatie wordt aangemaakt voor:"),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "ul",
                                  _vm._l(_setup.pandGoals, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_setup.translateGoals(item)) +
                                          " "
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Gebouw Details")]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Referentie:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.custReference)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Omschrijving:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.details.descr)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Geldig van:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.valFrom)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Geldig tot:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.valTill)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Gerbruiksoppervlakte (GO):"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.details.go)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Brutto vloeroppervlakte (BVO):"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.details.bvo)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Dakoppervlakte (DO):"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.details.do)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Type:")]),
                                  ]),
                                  _setup.location.details.typ
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.locationTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.details.typ
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("ECM Functie:")]),
                                  ]),
                                  _setup.location.details.ecmFuncId
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.ecmFunctions.find(
                                              (x) =>
                                                x.id ===
                                                _setup.location.details
                                                  .ecmFuncId
                                            ).name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("WEii Bouwbesluit:")]),
                                  ]),
                                  _setup.location.weiiBldDecreeKey
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.weiiBldDecrees.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.weiiBldDecreeKey
                                            ).name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("WEii Klasse:")]),
                                  ]),
                                  _setup.location.details.weiiBldCatKey
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.weiiBldCategories.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.details
                                                  .weiiBldCatKey
                                            ).name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("EED:")]),
                                  ]),
                                  _setup.location.details.eedTyp
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.eedTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.details.eedTyp
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Bouwdatum:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.details.consDat)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Partij Referentie:"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.partyReference)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Partij Rol:")]),
                                  ]),
                                  _setup.location.partyRole
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.partyRoles.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.partyRole
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Installatie Groep:"),
                                    ]),
                                  ]),
                                  _setup.location.installationGroupId
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.installationGroups.find(
                                              (x) =>
                                                x.id ===
                                                _setup.location
                                                  .installationGroupId
                                            ).ref
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Gebouw Groep:")]),
                                  ]),
                                  _setup.location.buildingGroupKey
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.constructionSourceTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.buildingGroupKey
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("ECM Groep:")]),
                                  ]),
                                  _setup.location.ecmGroupId
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.measureGroups.find(
                                              (x) =>
                                                x.id ===
                                                _setup.location.ecmGroupId
                                            ).reference
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("BAG")]),
                              _c("v-data-table", {
                                attrs: {
                                  dense: "",
                                  headers: _setup.BAGheaders,
                                  "hide-default-footer": "",
                                  items: _setup.bagSelected,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Constructie")]),
                              _vm._l(_setup.selectedParts, function (item) {
                                return _c(
                                  "div",
                                  { key: item.key },
                                  [
                                    item.rcCategory != undefined
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _setup.getKindDescription(
                                                        item.kindKey
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [_vm._v("Rc:")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.translateAssetConfig(
                                                      _setup.insulationAndGlazing,
                                                      item.rcCategory
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  _vm._s(Math.abs(item.rcValue))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.uCategory != undefined
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _setup.getKindDescription(
                                                        item.kindKey
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [_vm._v("U:")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.translateAssetConfig(
                                                      _setup.insulationAndGlazing,
                                                      item.uCategory
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  _vm._s(Math.abs(item.uValue))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.gCategory != undefined
                                      ? _c(
                                          "v-row",
                                          [
                                            _c("v-col", {
                                              attrs: { cols: "6" },
                                            }),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [_vm._v("G:")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _setup.translateAssetConfig(
                                                      _setup.insulationAndGlazing,
                                                      item.gCategory
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  _vm._s(Math.abs(item.gValue))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Adres")]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Omschrijving:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.details.descr)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Postcode:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.address.pc)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Stad:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.address.city)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Land:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.address.country)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Bron Type:")]),
                                  ]),
                                  _setup.location.address.srcTyp &&
                                  _setup.addressSourceTypesLoaded
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.addressSourceTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.address.srcTyp
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Bron Referentie:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.address.srcRef)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Straat:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.address.street)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Huisnummer:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.address.hn)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Toevoeging:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.address.hna)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Lengtegraad (lon):"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.address.lon)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [
                                      _vm._v("Breedtegraad (lat):"),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(_vm._s(_setup.location.address.lat)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Type:")]),
                                  ]),
                                  _setup.location.address.typ
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.addressableObjectTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.address.typ
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Adres Type:")]),
                                  ]),
                                  _setup.location.address.adrTyp
                                    ? _c("v-col", { attrs: { cols: "6" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _setup.addressTypes.find(
                                              (x) =>
                                                x.key ===
                                                _setup.location.address.adrTyp
                                            ).description
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Installatie")]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Verwarming:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.heating,
                                          _setup.location.installation.heating
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Koeling:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.cooling,
                                          _setup.location.installation.cooling
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Ventilatie:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.ventilation,
                                          _setup.location.installation
                                            .ventilation
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Verlichting:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.lighting,
                                          _setup.location.installation.lighting
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Zonnepanelen:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.pv,
                                          _setup.location.installation.pv
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Warm Water:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _setup.translateAssetConfig(
                                          _setup.domesticHotWater,
                                          _setup.location.installation
                                            .domesticHotWater
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Label")]),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Energie Label:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.label.energyLabel)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Energie Index:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.label.energyIndex)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c("strong", [_vm._v("Energiebron:")]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm._v(
                                      _vm._s(_setup.location.label.sourceKey)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        { staticClass: "boxed", attrs: { fluid: "" } },
                        [
                          _c("header", [_vm._v("Energiedata")]),
                          _c("v-data-table", {
                            attrs: {
                              dense: "",
                              headers: _setup.Registerheaders,
                              "hide-default-footer": "",
                              items: _setup.selectedRegisters,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-container",
                                        { attrs: { fluid: "" } },
                                        [
                                          item.usages.length > 0
                                            ? [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", {
                                                      attrs: { cols: "1" },
                                                    }),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("Jaar"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "2" } },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("Waarde"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  _setup._.sortBy(
                                                    item.usages,
                                                    "year"
                                                  ),
                                                  function (usage) {
                                                    return _c(
                                                      "v-row",
                                                      { key: usage.key },
                                                      [
                                                        _c("v-col", {
                                                          attrs: { cols: "1" },
                                                        }),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "4",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(usage.year)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                usage.value
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ]
                                            : _c(
                                                "v-row",
                                                [
                                                  _c("v-col", {
                                                    attrs: { cols: "1" },
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _vm._v(
                                                        "Geen verbruiken opgegeven"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _setup.saving
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-center": "",
                                    "align-center": "",
                                  },
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      size: 70,
                                      width: 7,
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "normal",
                                    disabled: _setup.isSaving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.previousStep()
                                    },
                                  },
                                },
                                [_vm._v("Vorige stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _setup.isSaving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.save()
                                    },
                                  },
                                },
                                [_vm._v("Opslaan")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "normal",
                                    disabled: _setup.isSaving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _setup.cancel()
                                    },
                                  },
                                },
                                [_vm._v("Annuleren")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }