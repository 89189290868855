<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import router from "@/router/index";
import { navigateToLocation } from "@/composables/general.js";
import { postLocationWizard } from "@/services/api/location.api";
import {
  getPartyRoles,
  getAddressTypes,
  getAddressableObjectTypes,
  getLocationTypes,
  getInstallationGroups,
  getConstructionBoundaryTypes,
  getConstructionSourceTypes,
  getConstructionKinds,
  getConstructionInsulationCategory,
  getAddressSourceTypes,
  getMeasureGroups,
  severityCode,
  getEcmFunctions,
  getWeiiBldCategories,
  getEedTypes,
  getLabelSources,
  getRegisterTypes,
  getRegisterEnergyTypes,
  getRegisterUnitTypes,
} from "@/services/api/masterdata.api";
import { emptyGuid } from "@/composables/miscellaneous.js";
import { constructionKind } from "@/services/constructionKind";
import _ from "lodash";
import { useCacheStore } from "@/stores/cache";
import DateInput from "@/components/general/DateInput.vue";
import BagSearch from "@/components/location/BagSearch.vue";
import EnergyLabelSearch from "@/components/location/EnergyLabelSearch.vue";
import ConstructionKindInput from "@/components/location/ConstructionKindInput.vue";
import RegisterInput from "@/views/project/location/wizard/RegisterInput.vue";

const cacheStore = useCacheStore();

setInteractionMode("eager");

const showKinds = [
  constructionKind.frontage,
  constructionKind.frontageExclAus,
  constructionKind.frontageInclPanels,
  constructionKind.roof,
  constructionKind.panel,
  constructionKind.floor,
  constructionKind.door,
  constructionKind.glass,
  constructionKind.glassPrimary,
  constructionKind.glassSecondary,
];

const currentStep = ref(1);
const saving = ref(false);
const parties = ref([]);
const locationTypes = ref([]);
const addressableObjectTypes = ref([]);
const addressTypes = ref([]);
const addressSourceTypes = ref([]);
const installationGroups = ref([]);
const constructionSourceTypes = ref([]);
const measureGroups = ref([]);
const bagSelected = ref([]);
const weiiBldDecrees = ref([]);
const allWeiiBldCategories = ref([]);
const weiiBldCategories = ref([]);
const eedTypes = ref([]);
const ecmFunctions = ref([]);
const partyRoles = ref([]);
const pandGoals = ref([]);
const location = ref({
  valFrom: undefined,
  valTill: undefined,
  typ: undefined,
  details: {
    descr: undefined,
    typ: undefined,
    go: undefined,
    bvo: undefined,
    weiiBldCatKey: undefined,
    eedTyp: undefined,
    ecmFuncId: undefined,
    consDat: undefined,
  },
  address: {
    descr: undefined,
    street: undefined,
    hn: undefined,
    hl: undefined,
    hna: undefined,
    pc: undefined,
    city: undefined,
    country: undefined,
    lon: undefined,
    lat: undefined,
    typ: undefined,
    adrTyp: undefined,
    srcTyp: "None",
    srcRef: undefined,
  },
  bagData: [],
  partyReference: undefined,
  partyRole: undefined,
  weiiBldDecreeKey: undefined,
  bagId: undefined,
  installationGroupId: undefined,
  buildingGroupKey: undefined,
  ecmGroupId: undefined,
  installation: {
    heating: undefined,
    cooling: undefined,
    ventilation: undefined,
    lighting: undefined,
    pv: undefined,
    domesticHotWater: undefined,
  },
  construction: undefined,
  label: {
    energyLabel: undefined,
    energyIndex: undefined,
    energyDemand: undefined,
    primaryFossilEnergy: undefined,
    primaryFossilEnergyForfaitair: undefined,
    shareOfRenewableEnergy: undefined,
    recordedDate: undefined,
    registrationDate: undefined,
    sourceKey: undefined,
    modBy: undefined,
    mutDat: undefined,
    usableSurface: undefined,
    usableLossSurface: undefined,
    usableGeometry: undefined,
    co2: undefined,
    demandHeatNet: undefined,
    energyStandard: undefined,
    energyRenewable: undefined,
    usageElectricity: undefined,
    usageGas: undefined,
    usageHeat: undefined,
    fossilEnergyUsageNonPrimary: undefined,
    totalUsageNonPrimary: undefined,
    usageVentilation: undefined,
    usageHeating: undefined,
    usageWater: undefined,
    usageCooling: undefined,
    usageHumidification: undefined,
    usageDehumidification: undefined,
    usageLighting: undefined,
    usageAuxiliaryElectrical: undefined,
    usageAuxiliaryPilotFlame: undefined,
    usageElectricityGeneration: undefined,
    demandHeat: undefined,
    demandHotWater1: undefined,
    demandHotWater2: undefined,
    demandCold: undefined,
  },
});

const energyLabels = ref(["A+++++", "A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G", ""]);
const labelSources = ref([]);

const labelSourcesLoaded = ref(false);
const partiesLoaded = ref(false);
const addressTypesLoaded = ref(false);
const addressableObjectTypesLoaded = ref(false);
const addressSourceTypesLoaded = ref(false);
const installationGroupsLoaded = ref(false);
const locationTypesLoaded = ref(false);
const measureGroupsLoaded = ref(false);
const constructionSourceTypesLoaded = ref(false);
const categoriesLoaded = ref(false);
const ecmFunctionsLoaded = ref(false);
const eedTypesLoaded = ref(false);
const insulationCategoriesLoaded = ref(false);
const registerTypesLoaded = ref(false);
const registerEnergyTypesLoaded = ref(false);
const registerUnitTypesLoaded = ref(false);
const partyRolesLoaded = ref(false);
const kindsLoaded = ref(false);
const sourceTypesLoaded = ref(false);
const boundaryTypesLoaded = ref(false);

const energyLabelSelected = ref(false);
const selectedParts = ref([]);
const selectedRegisters = ref([]);

const loadedKinds = ref([]);
const sourceTypes = ref([]);
const boundaryTypes = ref([]);
const insulationCategories = ref([]);
const registerTypes = ref([]);
const registerEnergyTypes = ref([]);
const registerUnitTypes = ref([]);

const isSaving = ref(false);

const heating = [
  { name: "CR-ketel", value: "CONVENTIONAL_BOILER" },
  { name: "VR-ketel", value: "IMPROVED_EFFICIENCY_BOILER" },
  { name: "HR-ketel", value: "HIGH_EFFICIENCY_BOILER" },
  { name: "Biomassaketel", value: "BIOMASS_BOILER" },
  { name: "WKO met warmtepomp", value: "ATES_HEAT_PUMP" },
  { name: "Stadsverwarming", value: "DISTRICT_HEATING" },
  { name: "Buitenlucht warmtepomp", value: "OUTSIDE_AIR_HEAT_PUMP" },
  { name: "Buitenlucht/water warmtepomp met elektrische naverwarming", value: "OUTSIDE_AIR_HEAT_PUMP_BI_ELEC" },
  { name: "Buitenlucht/water warmtepomp met gasgestookte ketel", value: "OUTSIDE_AIR_HEAT_PUMP_BI_GAS" },
  { name: "Retourlucht/water warmtepomp", value: "EXHAUST_AIR_HEAT_PUMP" },
  { name: "Retourlucht/water warmtepomp met elektrische naverwarming", value: "EXHAUST_AIR_HEAT_PUMP_BI_ELEC" },
  { name: "Retourlucht/water warmtepomp met gasgestookte ketel", value: "EXHAUST_AIR_HEAT_PUMP_BI_GAS" },
];
const cooling = [
  { name: "Geen", value: "NONE" },
  { name: "Compressiekoelmachine", value: "ELECTRICAL_COMPRESSION_COOLING" },
  { name: "WKO bronkoeling", value: "ATES_HEAT_PUMP" },
  { name: "Stadskoeling", value: "DISTRICT_COOLING" },
];
// const insulation = [
//   { name: "Slecht (0cm)", value: "POOR" },
//   { name: "Matig (4cm)", value: "AVERAGE" },
//   { name: "Goed (9cm)", value: "WELL" },
//   { name: "Zeer goed (12cm)", value: "VERYWELL" },
// ];
// const glazing = [
//   { name: "Enkel glas", value: "SINGLE" },
//   { name: "Dubbel glas", value: "DOUBLE" },
//   { name: "Driedubbel glas", value: "HRPP" },
//   { name: "HR++ glas", value: "TRIPLE" },
// ];
const ventilation = [
  { name: "Natuurlijke ventilatie", value: "NATURAL" },
  { name: "Mechanische afzuiging", value: "EXHAUST" },
  { name: "Gebalanceerd", value: "MECHANICAL" },
  { name: "Gebalanceerd met WTW", value: "MECHANICAL_HEAT_RECOVERY" },
  { name: "Gebalanceerd met CO₂ sturing op zone niveau", value: "MECHANICAL_CO2_ZONE" },
  { name: "Gebalanceerd met CO₂ sturing op ruimte niveau", value: "MECHANICAL_CO2_ROOM" },
];
const lighting = [
  { name: "Conventioneel", value: "CONVENTIONAL" },
  { name: "TL5 met vertrekschakeling", value: "PRESENCE_DETECTION_TL_5" },
  { name: "TL5 met zuinige schakeling", value: "ECONOMICAL_SWITCHING_TL_5" },
  { name: "LED met zuinige schakeling in alle ruimtes", value: "ECONOMICAL_SWITCHING_LED" },
  { name: "LED met zuinige schakeling in gangen", value: "ECONOMICAL_SWITCHING_LED_CORRIDOR" },
  { name: "LED met zuinige schakeling retrofit", value: "ECONOMICAL_SWITCHING_LED_RETROFIT" },
];
const pv = [
  { name: "Geen zonnepanelen", value: "VALUE_0" },
  { name: "Zonnepanelen op 30% van dakoppervlak", value: "VALUE_30" },
  { name: "Zonnepanelen op 50% van dakoppervlak", value: "VALUE_50" },
  { name: "Zonnepanelen op 70% van dakoppervlak", value: "VALUE_70" },
];
const domesticHotWater = [
  { name: "Elektrische boiler", value: "ELECTRICAL_BOILER" },
  { name: "Gas boiler zonder zonnecollector", value: "GAS_BOILER" },
  { name: "Gas boiler met zonnecollector", value: "GAS_BOILER_WITH_COLLECTOR" },
  { name: "Biomassaketel", value: "BIOMASS_BOILER" },
  { name: "Stadsverwarming", value: "DISTRICT_HEATING" },
];
const insulationAndGlazing = [
  { name: "Handmatig ingevuld", value: "HAND" },
  { name: "Slecht (0cm)", value: "POOR" },
  { name: "Matig (4cm)", value: "AVERAGE" },
  { name: "Goed (9cm)", value: "WELL" },
  { name: "Zeer goed (12cm)", value: "VERYWELL" },
  { name: "Enkel glas", value: "SINGLE" },
  { name: "Dubbel glas", value: "DOUBLE" },
  { name: "HR++ glas", value: "HRPP" },
  { name: "Driedubbel glas", value: "TRIPLE" },
];

const BAGheaders = [
  { text: "Type", value: "typeDescription", sortable: false },
  { text: "Adres type", value: "addressTypeDescription", sortable: false },
  { text: "Adres", value: "composedAddress", sortable: false },
  { text: "Postcode", value: "postalCode", sortable: false },
  { text: "Stad", value: "city", sortable: false },
];

const Registerheaders = [
  { text: "Type", value: "registerTypeDesc", sortable: false, width: 210 },
  { text: "Energie type", value: "registerEnergyTypeDesc", sortable: false, width: 140 },
  { text: "Eenheid", value: "registerUnitTypeDesc", sortable: false, width: 150 },
  { text: "Ean", value: "ean", sortable: false, width: 150 },
  { text: "Verbruiken", value: "id", sortable: false, width: 250 },
];

const masterDataLoaded = computed(
  () =>
    partiesLoaded.value &&
    addressableObjectTypesLoaded.value &&
    addressTypesLoaded.value &&
    addressSourceTypesLoaded.value &&
    partyRolesLoaded.value &&
    locationTypesLoaded.value &&
    installationGroupsLoaded.value &&
    constructionSourceTypesLoaded.value &&
    measureGroupsLoaded.value &&
    categoriesLoaded.value &&
    ecmFunctionsLoaded.value &&
    eedTypesLoaded.value &&
    labelSourcesLoaded.value &&
    kindsLoaded.value &&
    insulationCategoriesLoaded.value &&
    registerTypesLoaded.value &&
    registerEnergyTypesLoaded.value &&
    registerUnitTypesLoaded.value
);

const isAtLeastOneChecked = computed(() => pandGoals.value.length > 0);
const isBagSelected = computed(() => bagSelected.value.length > 0);
//const isLabelSelected = computed(() => location.value.label.energyLabel !== undefined);
const validationRules = computed(() => {
  if (pandGoals.value.length > 0 && pandGoals.value.includes("EN")) {
    return "required";
  }

  return "";
});
const kinds = computed(() => {
  if (kindsLoaded.value) {
    return loadedKinds.value.filter((item) => showKinds.includes(item.key));
  } else {
    return [];
  }
});

watch(
  () => location.value.weiiBldDecreeKey,
  () => {
    filterWeiiBldCategories(location.value.weiiBldDecreeKey);
    if (location.value.weiiBldDecreeKey === null) {
      location.value.details.weiiBldCatKey = null;
    }
    else {
      location.value.details.weiiBldCatKey = null;
    }
  }
);

watch(
  () => pandGoals.value,
  () => {
    if (!pandGoals.value.includes("EN")) {
      location.value.details.consDat = undefined;
      location.value.details.do = undefined;
    }
    if (!pandGoals.value.includes("PPM")) {
      location.value.weiiBldDecreeKey = undefined;
      location.value.details.weiiBldCatKey = undefined;
      location.value.details.ecmFuncId = undefined;
      location.value.details.go = undefined;
    }
  },
  { deep: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (masterDataLoaded.value) {
      currentStep.value = 2;
    }
  }
);

onMounted(() => {
  loadParties();
  loadAddressTypes();
  loadAddressableObjectTypes();
  loadAddressSourceTypes();
  loadPartyRoles();
  loadLocationTypes();
  loadInstallationGroups();
  loadConstructionSourceTypes();
  loadMeasureGroups();
  loadWeiiBldDecreesAndCategories();
  loadEcmFunctions();
  loadEedTypes();
  loadLabelSources();
  loadKinds();
  loadSourceTypes();
  loadBoundaryTypes();
  loadInsulationCategories();
  loadRegisterTypes();
  loadRegisterEnergyTypes();
  loadRegisterUnitTypes();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadParties() {
  const response = await cacheStore.parties();
  if (response.success) {
    parties.value = _.map(_.sortBy(response.list, "name"), (option) => {
      return {
        reference: option.reference,
        name: option.name,
      };
    });
    partiesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de partijen is mislukt! " + response.error);
  }
}

async function loadAddressTypes() {
  const response = await getAddressTypes();
  if (response && !response.error) {
    addressTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres types is mislukt! " + response.error);
  }
}

async function loadAddressableObjectTypes() {
  const response = await getAddressableObjectTypes();
  if (response && !response.error) {
    addressableObjectTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressableObjectTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adressable object types is mislukt! " + response.error);
  }
}

async function loadAddressSourceTypes() {
  const response = await getAddressSourceTypes();
  if (response && !response.error) {
    addressSourceTypes.value = _.sortBy([...response.data.keyDescList], "description");
    addressSourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adres types is mislukt! " + response.error);
  }
}

async function loadPartyRoles() {
  const response = await getPartyRoles();
  if (response && !response.error) {
    partyRoles.value = _.sortBy([...response.data.keyDescList], "prio");
    console.log("loadPartyRoles, partyRoles: ", partyRoles.value);
    partyRolesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de rollen is mislukt! " + response.error);
  }
}

async function loadLocationTypes() {
  const response = await getLocationTypes();
  if (response && !response.error) {
    locationTypes.value = _.sortBy([...response.data.keyDescList], "description");
    locationTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de location types is mislukt! " + response.error);
  }
}

async function loadInstallationGroups() {
  const response = await getInstallationGroups();
  if (response.severity <= severityCode.warning) {
    installationGroups.value = [...response.data.installationGroups];
    installationGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie groepen is mislukt! " + response.error);
  }
}

async function loadConstructionSourceTypes() {
  const response = await getConstructionSourceTypes();
  if (response && !response.error) {
    constructionSourceTypes.value = _.sortBy([...response.data.keyDescList], "description");
    constructionSourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction gegevens groepen is mislukt! " + response.error);
  }
}

async function loadMeasureGroups() {
  const response = await getMeasureGroups();
  if (response && !response.error) {
    measureGroups.value = _.sortBy([...response.data.appliedGroups], "reference");
    measureGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de maatregelen groepen is mislukt! " + response.error);
  }
}

async function loadWeiiBldDecreesAndCategories() {
  const response = await getWeiiBldCategories();
  if (response && !response.error) {
    allWeiiBldCategories.value = _.sortBy([...response.data.weiiBldCategories], "name");
    allWeiiBldCategories.value.unshift({ key: null, weiiBldDecreeKey: null, weiiBldDecreeName: "Geen", name: "Geen" });
    weiiBldCategories.value = allWeiiBldCategories.value;
    let uniqueBldDecrees = [...new Map(allWeiiBldCategories.value.map((item) => [item["weiiBldDecreeName"], item])).values()];
    weiiBldDecrees.value = _.sortBy(
      [
        ...uniqueBldDecrees.map(function (item) {
          return { key: item.weiiBldDecreeKey, name: item.weiiBldDecreeName };
        }),
      ],
      "name"
    );
    categoriesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de WeiiBldCategories is mislukt! " + response.error);
  }
}

async function loadEedTypes() {
  const response = await getEedTypes();
  if (response && !response.error) {
    eedTypes.value = _.sortBy([...response.data.keyDescList], "description");
    eedTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de eed types is mislukt! " + response.error);
  }
}

async function loadEcmFunctions() {
  const response = await getEcmFunctions();
  if (response && !response.error) {
    ecmFunctions.value = _.sortBy([...response.data.ecmFunctions], "name");
    ecmFunctions.value.unshift({ id: emptyGuid, code: "Geen", name: "Geen", descr: "Geen" });
    ecmFunctionsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de ecm functies is mislukt! " + response.error);
  }
}

async function loadLabelSources() {
  const response = await getLabelSources();
  if (response && !response.error) {
    labelSources.value = _.sortBy([...response.data.keyDescList], "description");
    console.log("loadLabelSources, labelSources: ", labelSources.value);

    labelSourcesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de bronnen is mislukt! " + response.error);
  }
}

function filterWeiiBldCategories(key) {
  weiiBldCategories.value = _.filter(allWeiiBldCategories.value, function (categories) {
    return categories.weiiBldDecreeKey === key;
  });
}

async function loadSourceTypes() {
  const response = await getConstructionSourceTypes();
  if (response && !response.error) {
    sourceTypes.value = _.sortBy([...response.data.keyDescList], "description");
    sourceTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction source types is mislukt! " + response.error);
  }
}

async function loadBoundaryTypes() {
  const response = await getConstructionBoundaryTypes();
  if (response && !response.error) {
    boundaryTypes.value = _.sortBy([...response.data.keyDescList], "description");

    boundaryTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction boundary types is mislukt! " + response.error);
  }
}

async function loadKinds() {
  const response = await getConstructionKinds();
  if (response && !response.error) {
    loadedKinds.value = _.sortBy([...response.data.keyDescList], "description");
    kindsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction kinds is mislukt! " + response.error);
  }
}

async function loadInsulationCategories() {
  const response = await getConstructionInsulationCategory();
  if (response && !response.error) {
    insulationCategories.value = response.data.insulationCategories;
    console.log("loadInsulationCategories: ", insulationCategories.value);
    insulationCategoriesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de constructie isolatie waarden is mislukt! " + response.error);
  }
}

async function loadRegisterTypes() {
  const response = await getRegisterTypes();
  if (response && !response.error) {
    registerTypes.value = _.sortBy(
      response.data.registerTypes.filter((x) => x.source == null).map((x) => ({ key: x.id, description: x.descr })),
      "description"
    );
    registerTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de register types is mislukt! " + response.error);
  }
}

async function loadRegisterEnergyTypes() {
  const response = await getRegisterEnergyTypes();
  if (response && !response.error) {
    registerEnergyTypes.value = _.sortBy(
      response.data.energyTypes.map((x) => ({ key: x.id, description: x.descr })),
      "description"
    );
    registerEnergyTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de register energy types is mislukt! " + response.error);
  }
}

async function loadRegisterUnitTypes() {
  const response = await getRegisterUnitTypes();
  if (response && !response.error) {
    registerUnitTypes.value = _.sortBy(
      response.data.unitTypes.map((x) => ({ key: x.id, description: `${x.name} (${x.descr})` })),
      "description"
    );
    registerUnitTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de register unit types is mislukt! " + response.error);
  }
}

function getPartData(kindKey) {
  const part = selectedParts.value.find((x) => {
    return x.kindKey === kindKey;
  });
  return part !== undefined ? part : undefined;
}

function getKindDescription(kindKey) {
  const kind = loadedKinds.value.find((x) => {
    return x.key === kindKey;
  });
  return kind !== undefined ? kind.description : undefined;
}

function getRegisterTypeDescription(key) {
  const type = registerTypes.value.find((x) => {
    return x.key === key;
  });
  return type !== undefined ? type.description : undefined;
}

function getRegisterEnergyTypeDescription(key) {
  const type = registerEnergyTypes.value.find((x) => {
    return x.key === key;
  });
  return type !== undefined ? type.description : undefined;
}

function getRegisterUnitTypeDescription(key) {
  const type = registerUnitTypes.value.find((x) => {
    return x.key === key;
  });
  return type !== undefined ? type.description : undefined;
}

function updatePart(data) {
  console.log("updatePart, data, selectedParts: ", data, selectedParts.value);
  const partIndex = selectedParts.value.findIndex((x) => {
    return x.kindKey === data.kindKey;
  });
  if (partIndex > -1) {
    if (data.active) {
      selectedParts.value.splice(partIndex, 1, { ...data });
    } else {
      selectedParts.value.splice(partIndex, 1);
    }
  } else {
    if (data.active) {
      selectedParts.value.push({ ...data });
    }
  }
}

function updateRegisters(data) {
  selectedRegisters.value = data;
  for (let index = 0; index < selectedRegisters.value.length; index++) {
    const element = selectedRegisters.value[index];
    element.registerTypeDesc = getRegisterTypeDescription(element.registerTypeId);
    element.registerEnergyTypeDesc = getRegisterEnergyTypeDescription(element.registerEnergyTypeId);
    element.registerUnitTypeDesc = getRegisterUnitTypeDescription(element.registerUnitTypeId);
  }
  console.log("updateRegisters, selectedRegisters: ", selectedRegisters.value);
}

async function save() {
  isSaving.value = true;
  saving.value = true;
  const bagSelection = bagSelected.value.map((el) => ({ buildingId: el.buildingId, residenceId: el.residenceId, numberId: el.numberId }));
  location.value.bagData = bagSelection;

  location.value.constructionParts = [...selectedParts.value];
  location.value.registers = [...selectedRegisters.value];

  const response = await postLocationWizard(location.value);

  switch (response.severity) {
    case severityCode.hint:
    case severityCode.warning:
      proxy.$toaster.warning(response.message);
      break;
    case severityCode.error:
      if (response.message == "conflict") {
        proxy.$toaster.error("Er is een conflict op de tijdlijn!");
      } else {
        proxy.$toaster.error("Bewaren van gegevens is mislukt! " + response.message);
      }
      break;
    default:
      proxy.$toaster.success("Gegevens van deze locatie zijn opgeslagen.");
      break;
  }
  saving.value = false;

  if (response.severity != severityCode.error) {
    navigateToLocation(response.data.reference);
  }
  isSaving.value = false;
}

function cancel() {
  router.push({ name: "Locations" });
}

function previousStep() {
  currentStep.value > 2 ? currentStep.value-- : (currentStep.value = 2);
}

//todo niet meest charmante oplossing maar wel snelste. Zal iets met toRefs() moeten worden?
const obs2 = ref(null);
const obs3 = ref(null);
const obs4 = ref(null);
const obs5 = ref(null);
const obs6 = ref(null);
const obs7 = ref(null);
const obs8 = ref(null);
const obs9 = ref(null);

//todo ook dit... tja... hoe oplossen??
async function nextStep() {
  let valid = false;
  switch (currentStep.value) {
    case 2:
      valid = await obs2.value.validate();
      console.log("addlocation doel, nextStep: ", valid);
      break;
    case 3:
      valid = await obs3.value.validate();
      break;
    case 4:
      valid = await obs4.value.validate();
      break;
    case 5:
      valid = await obs5.value.validate();
      break;
    case 6:
      valid = await obs6.value.validate();
      break;
    case 7:
      valid = await obs7.value.validate();
      break;
    case 8:
      valid = await obs8.value.validate();
      break;
    case 9:
      valid = await obs9.value.validate();
      break;
  }
  if (valid) {
    currentStep.value++;
  }
}

function allAreEqual(array) {
  if (!array.length) return true;
  return (
    array.reduce(function (a, b) {
      return a === b ? a : !b;
    }) === array[0]
  );
}

function selectedBag(addresses) {
  bagSelected.value = addresses;
  console.log("selectedBag, addresses: ", addresses);
  if (bagSelected.value.length > 0 && bagSelected.value) {
    // Hier moet de lijst van geselecteerde BagId's (bagSelected) teruggegeven worden (pand(en) en ruimte(s))
    // Evt street, pc, city, enz. kopieren naar address indien ze in de hele lijst hetzelfde zijn.
    // Rest moet de gebruiker zelf invullen.
    // Het address is voor het adres van parent locatie.
    // Referentie is niet de location.reference (INNAX-id) maar de klant referentie (addressableObjectDetails.Reference, bijv. de prop-ref)
    // De lijst van BagIds naar de backend moet hetzelfde afgehandeld worden als bij "Toevoegen sublocatie"

    const allPostalCodesAreEqual = allAreEqual(bagSelected.value.map((a) => a.postalCode));
    const allHouseNumbersAreEqual = allAreEqual(bagSelected.value.map((a) => a.houseNumber));
    const allHouseNumberAdditionsAreEqual = allAreEqual(bagSelected.value.map((a) => a.houseNumberAddition));
    const allStreetNamesAreEqual = allAreEqual(bagSelected.value.map((a) => a.singleStreetName));
    const allCitiesAreEqual = allAreEqual(bagSelected.value.map((a) => a.city));
    const allCcomposedAddressesAreEqual = allAreEqual(bagSelected.value.map((a) => a.composedAddress));
    const allConstructionDatesAreEqual = allAreEqual(bagSelected.value.map((a) => a.constructionDate));
    const allAddressableObjectTypesAreEqual = allAreEqual(bagSelected.value.map((a) => a.addressTypeDescription));
    const allLocationTypesAreEqual = allAreEqual(bagSelected.value.map((a) => a.typeDescription));

    if (allLocationTypesAreEqual) {
      console.log("selectBag, bagSelected[0].typeDescription, locationTypes: ", bagSelected.value[0].typeDescription, locationTypes.value);
      const locationType = locationTypes.value.find((x) => x.description === bagSelected.value[0].typeDescription);
      location.value.details.typ = locationType ? locationType.key : undefined;
    }
    if (allAddressableObjectTypesAreEqual) {
      const addressableObjectType = addressableObjectTypes.value.find((x) => x.description === bagSelected.value[0].addressTypeDescription);
      location.value.address.typ = addressableObjectType ? addressableObjectType.key : undefined;
    }

    location.value.address.pc = allPostalCodesAreEqual ? bagSelected.value[0].postalCode : "";
    location.value.address.hn = allHouseNumbersAreEqual ? bagSelected.value[0].houseNumber : "";
    location.value.address.hna = allHouseNumberAdditionsAreEqual ? bagSelected.value[0].houseNumberAddition : "";
    location.value.address.street = allStreetNamesAreEqual ? bagSelected.value[0].singleStreetName : "";
    location.value.address.city = allCitiesAreEqual ? bagSelected.value[0].city : "";
    location.value.address.country = "Nederland";
    location.value.address.lon = allCcomposedAddressesAreEqual ? bagSelected.value[0].lon : "";
    location.value.address.lat = allCcomposedAddressesAreEqual ? bagSelected.value[0].lat : "";

    location.value.address.descr = allCcomposedAddressesAreEqual ? bagSelected.value[0].composedAddress : "";
    location.value.details.descr = allCcomposedAddressesAreEqual ? bagSelected.value[0].composedAddress : "";
    location.value.valFrom = allConstructionDatesAreEqual ? bagSelected.value[0].constructionDate : "";
    location.value.details.consDat = allConstructionDatesAreEqual ? bagSelected.value[0].constructionDate : "";

    location.value.address.srcRef = bagSelected.value[0].numberId ? bagSelected.value[0].numberId : "";
    location.value.details.go = bagSelected.value[0].go ? bagSelected.value[0].go : "";
    location.value.details.bvo = Math.round(location.value.details.go / 0.9);
  } else {
    location.value.bagId = undefined;
    location.value.address.pc = undefined;
    location.value.address.hn = undefined;
    location.value.address.hna = undefined;
    location.value.address.city = undefined;
    location.value.address.country = undefined;
    location.value.address.lon = undefined;
    location.value.address.lat = undefined;
    location.value.address.srcRef = undefined;
    location.value.address.descr = undefined;
    location.value.details.descr = undefined;
    location.value.valFrom = undefined;
    location.value.details.go = undefined;
    location.value.details.bvo = undefined;
    location.value.details.consDat = undefined;
  }
}

function selectedEnergyLabel(label) {
  console.log("EnergyLabelDetail selectedEnergyLabel, label: ", label);
  energyLabelSelected.value = true;
  location.value.label.energyIndex = label.energyIndex;
  location.value.label.energyLabel = label.energyLabel;
  location.value.label.sourceKey = label.sourceKey;
  location.value.label.energyDemand = label.energyDemand;
  location.value.label.primaryFossilEnergy = label.primaryFossilEnergy;
  location.value.label.primaryFossilEnergyForfaitair = label.primaryFossilEnergyForfaitair;
  location.value.label.shareOfRenewableEnergy = label.shareOfRenewableEnergy;
  location.value.label.recordedDate = label.recordedDate;
  location.value.label.registrationDate = label.registrationDate;
  location.value.label.modBy = label.modBy;
  location.value.label.mutDat = label.mutDat;
  location.value.label.usableSurface = label.usableSurface;
  location.value.label.usableLossSurface = label.usableLossSurface;
  location.value.label.usableGeometry = label.usableGeometry;
  location.value.label.co2 = label.co2;
  location.value.label.demandHeatNet = label.demandHeatNet;
  location.value.label.energyStandard = label.energyStandard;
  location.value.label.energyRenewable = label.energyRenewable;
  location.value.label.usageElectricity = label.usageElectricity;
  location.value.label.usageGas = label.usageGas;
  location.value.label.usageHeat = label.usageHeat;
  location.value.label.fossilEnergyUsageNonPrimary = label.fossilEnergyUsageNonPrimary;
  location.value.label.totalUsageNonPrimary = label.totalUsageNonPrimary;
  location.value.label.usageVentilation = label.usageVentilation;
  location.value.label.usageHeating = label.usageHeating;
  location.value.label.usageWater = label.usageWater;
  location.value.label.usageCooling = label.usageCooling;
  location.value.label.usageHumidification = label.usageHumidification;
  location.value.label.usageDehumidification = label.usageDehumidification;
  location.value.label.usageLighting = label.usageLighting;
  location.value.label.usageAuxiliaryElectrical = label.usageAuxiliaryElectrical;
  location.value.label.usageAuxiliaryPilotFlame = label.usageAuxiliaryPilotFlame;
  location.value.label.usageElectricityGeneration = label.usageElectricityGeneration;
  location.value.label.demandHeat = label.demandHeat;
  location.value.label.demandHotWater1 = label.demandHotWater1;
  location.value.label.demandCold = label.demandCold;
  location.value.label.demandHotWater2 = label.demandHotWater2;
}

function checkDateValid() {
  return { on: ["input"] };
}

function translateAssetConfig(type, value) {
  if (!type || !value) {
    return "";
  } else {
    return _.find(type, { value: _.toUpper(value) }).name;
  }
}

function translateGoals(item) {
  if (item === "PPM") {
    return "Paris Proof Monitor";
  }
  if (item === "EN") {
    return "Energy Navigator";
  }
}
</script>

<template>
  <v-container fluid class="app-overview">
    <header class="app-welcome-message">
      <h1>Locatie toevoegen</h1>
    </header>
    <v-container fluid py-0>
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1"> Laden gegevens </v-stepper-step>
        <v-stepper-content step="1">
          <span :class="{ loadingDots: !masterDataLoaded }">Gegevens worden geladen</span>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 2" step="2"> Doel opgeven </v-stepper-step>
        <v-stepper-content step="2">
          <ValidationObserver ref="obs2">
            <v-row>
              <v-col>
                <v-layout class="form-group checkbox-group">
                  <input type="checkbox" id="PPM" value="PPM" v-model="pandGoals" />
                  <label for="PPM">Paris Proof Monitor</label>
                  <br />
                  <input type="checkbox" id="EN" value="EN" v-model="pandGoals" />
                  <label for="EN">Energy Navigator</label>
                  <div v-if="!isAtLeastOneChecked">Selecteer tenminste één optie.</div>
                </v-layout>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Party" rules="required" v-slot="{ errors }">
                  <v-select
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Partij"
                    :items="parties"
                    item-text="name"
                    item-value="reference"
                    v-model="location.partyReference" 
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Rol" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined
                    persistent-placeholder 
                    label="Rol" 
                    :items="partyRoles"
                    item-text="description" 
                    item-value="key" 
                    v-model="location.partyRole" 
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Installatiegroep" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Installatiegroep"
                    :items="installationGroups"
                    item-text="ref" 
                    item-value="id" 
                    v-model="location.installationGroupId"
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Bron bouwkundige gegevens" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Bron bouwkundige gegevens"
                    :items="constructionSourceTypes" 
                    item-text="description" 
                    item-value="key"
                    v-model="location.buildingGroupKey" 
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Maatregelengroep" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Maatregelengroep"
                    :items="measureGroups" 
                    item-text="reference" 
                    item-value="id" 
                    v-model="location.ecmGroupId"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()" :disabled="!isAtLeastOneChecked">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 3" step="3"> Zoek locatie in BAG </v-stepper-step>
        <v-stepper-content step="3">
          <ValidationObserver ref="obs3">
            <v-row>
              <v-col> Kies Sublocatie adres(sen) </v-col>
            </v-row>
            <bag-search @selected="selectedBag"></bag-search>
            <v-row>
              <v-col> Adres </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Referentie" rules="required|max:50" v-slot="{ errors, valid }">
                  <v-text-field hide-details="auto" outlined persistent-placeholder label="Referentie" :counter="50" v-model="location.custReference" :error-messages="errors" :success="valid">
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Omschrijving" rules="required" v-slot="{ errors }">
                  <v-text-field hide-details="auto" outlined persistent-placeholder label="Omschrijving" v-model="location.details.descr" :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field hide-details="auto" outlined persistent-placeholder label="Straatnaam" v-model="location.address.street"> </v-text-field>
              </v-col>
              <v-col>
                <ValidationProvider name="Huisnummer" rules="numeric" v-slot="{ errors }">
                  <v-text-field outlined persistent-placeholder hide-details="auto" label="Huisnummer" v-model="location.address.hn" :error-messages="errors"> </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Huisletter" rules="max:1" v-slot="{ errors }">
                  <v-text-field hide-details="auto" outlined persistent-placeholder label="Huisletter" v-model="location.address.hl" :error-messages="errors"> </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <v-text-field hide-details="auto" outlined persistent-placeholder label="Toevoeging" v-model="location.address.hna"> </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <ValidationProvider name="Postcode" rules="postalcode" v-slot="{ errors }">
                  <v-text-field hide-details="auto" outlined persistent-placeholder label="Postcode" v-model="location.address.pc" :error-messages="errors"> </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <v-text-field hide-details="auto" outlined persistent-placeholder label="Woonplaats" v-model="location.address.city"> </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details="auto" outlined persistent-placeholder label="Land" v-model="location.address.country"> </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <ValidationProvider name="Lengtegraad" rules="required|double|between:-180,180" v-slot="{ errors }">
                  <v-text-field 
                    hide-details="auto" 
                    outlined persistent-placeholder 
                    label="Lengtegraad (lon)"
                    v-model="location.address.lon" 
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Breedtegraad" rules="required|double|between:-180,180" v-slot="{ errors }">
                  <v-text-field
                    hide-details="auto"
                    outlined persistent-placeholder
                    label="Breedtegraad (lat)"
                    v-model="location.address.lat"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Type"
                    :items="addressableObjectTypes" 
                    item-text="description" 
                    item-value="key"
                    v-model="location.address.typ" 
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Adres type" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Adres Type" 
                    :items="addressTypes"
                    item-text="description" 
                    item-value="key" 
                    v-model="location.address.adrTyp"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Bron type" rules="required" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto"
                    outlined 
                    persistent-placeholder 
                    label="Bron Type" 
                    readonly
                    :items="addressSourceTypes" 
                    item-text="description" 
                    item-value="key"
                    v-model="location.address.srcTyp" 
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="ConstructionDate" ref="consDat" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                  <date-input v-model="location.details.consDat" outlined persistent-placeholder label="Bouwdatum" :errors="errors" rules="required"> </date-input>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Geldig vanaf" ref="valFrom" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                  <date-input v-model="location.valFrom" outlined persistent-placeholder label="Geldig vanaf" :errors="errors" rules="required"> </date-input>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Geldig tot" ref="valTill" :rules="`date_between|date_after:${location.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                  <date-input v-model="location.valTill" outlined persistent-placeholder label="Geldig tot" :errors="errors"> </date-input>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()" :disabled="!isBagSelected">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 4" step="4"> Aanvullende gegevens </v-stepper-step>
        <v-stepper-content step="4">
          <ValidationObserver ref="obs4">
            <div v-if="pandGoals.length > 0 && pandGoals.includes('PPM')">
              <v-row>
                <v-col> Paris Proof Monitor </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                    <v-select 
                      hide-details="auto"
                      outlined 
                      persistent-placeholder 
                      label="Type" 
                      :items="locationTypes"
                      item-text="description" 
                      item-value="key" 
                      v-model="location.details.typ" 
                      :error-messages="errors"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider name="Functie" rules="required" v-slot="{ errors }">
                    <v-select 
                      hide-details="auto" 
                      outlined 
                      persistent-placeholder 
                      label="Functie" 
                      :items="ecmFunctions"
                      item-text="name" 
                      item-value="id" 
                      v-model="location.details.ecmFuncId" 
                      :error-messages="errors"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider name="WEii Bouwbesluit" rules="required" v-slot="{ errors }">
                    <v-select 
                      hide-details="auto" 
                      outlined 
                      persistent-placeholder 
                      label="WEii Bouwbesluit"
                      :items="weiiBldDecrees" 
                      item-text="name" 
                      item-value="key" 
                      v-model="location.weiiBldDecreeKey"
                      :error-messages="errors"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider name="WEii Klasse" rules="required" v-slot="{ errors }">
                      <v-select 
                        hide-details="auto" 
                        outlined 
                        persistent-placeholder 
                        label="WEii Klasse"
                        :items="weiiBldCategories" 
                        item-text="name" 
                        item-value="key"
                        v-model="location.details.weiiBldCatKey" 
                        :error-messages="errors"
                      >
                      </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider name="EED" rules="required" v-slot="{ errors }">
                    <v-select
                      hide-details="auto" 
                      outlined 
                      persistent-placeholder 
                      label="EED" 
                      :items="eedTypes"
                      item-text="description"
                      item-value="key" 
                      v-model="location.details.eedTyp"
                      :error-messages="errors"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <ValidationProvider name="Gebruiksoppervlakte" rules="required|numeric|between:0,9999999" v-slot="{ errors }">
                        <v-text-field 
                          type="number"
                          outlined
                          persistent-placeholder
                          hide-details="auto"
                          label="Gebruiksoppervlakte (GO)"
                          :value="location.details.go"
                          @input="location.details.go = $event !== '' ? $event : undefined"
                          :error-messages="errors"
                        >
                            <template slot="append">m²</template>
                        </v-text-field>
                    </ValidationProvider>
                </v-col>
              </v-row>
            </div>

            <div :style="[pandGoals.length === 2 ? { height: '50px' } : { height: '0px' }]"></div>

            <div v-if="pandGoals.length > 0 && pandGoals.includes('EN')">
              <v-row>
                <v-col> Energy Navigator </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <ValidationProvider name="Bouwdatum" rules="required|date_between:1500-01-01,2050-12-31" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          :value="location.details.consDat"
                          @input="location.details.consDat = $event !== '' ? $event : undefined"
                          outlined
                          persistent-placeholder
                          label="Bouwdatum"
                          :min="new Date(1500, 0, 1)"
                          :errors="errors"
                        >
                        </date-input>
                    </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider name="Dakoppervlakte" rules="required|numeric|between:0,9999999" v-slot="{ errors }">
                    <v-text-field 
                      type="number" 
                      hide-details="auto" 
                      outlined 
                      persistent-placeholder
                      label="Dakoppervlakte (DO)" 
                      :value="location.details.do"
                      @input="location.details.do = $event !== '' ? $event : undefined" 
                      :error-messages="errors"
                    >
                      <template slot="append">m²</template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>

            <div :style="[pandGoals.length > 0 ? { height: '50px' } : { height: '0px' }]"></div>

            <div>
              <v-row>
                <v-col> Gedeelde gegevens (PPM én EN) </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider name="Bruto vloeroppervlakte" rules="required|numeric|between:0,9999999" v-slot="{ errors }">
                    <v-text-field 
                    type="number" 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder
                    label="Bruto vloeroppervlakte (BVO)" 
                    :value="location.details.bvo"
                    @input="location.details.bvo = $event !== '' ? $event : undefined" 
                    :error-messages="errors"
                    >
                      <template slot="append">m²</template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 5" step="5"> Energielabel </v-stepper-step>
        <v-stepper-content step="5">
          <ValidationObserver ref="obs5">
            <v-row>
              <energy-label-search :postalCode="location.address.pc" :houseNumber="location.address.hn" :houseNumberAddition="location.address.hna" @selected="selectedEnergyLabel">
              </energy-label-search>
            </v-row>

            <v-row v-if="energyLabelSelected">
              <v-col>Geselecteerd label:</v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Label" rules="required" v-slot="{ errors }">
                  <v-select hide-details="auto" outlined persistent-placeholder label="Label" :items="energyLabels" v-model="location.label.energyLabel" :error-messages="errors"></v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                  <ValidationProvider name="Bron" rules="required" v-slot="{ errors }">
                      <v-select 
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        label="Bron"
                        :items="labelSources"
                        item-text="description"
                        item-value="key"
                        v-model="location.label.sourceKey"
                        :error-messages="errors"
                      ></v-select>
                  </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="EnergyIndex" rules="decimal:4|between:0,9" v-slot="{ errors }">
                  <v-text-field hide-details="auto" outlined persistent-placeholder type="number" label="Energie-index" v-model="location.label.energyIndex" :error-messages="errors"> </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 6" step="6"> Installatiegegevens </v-stepper-step>
        <v-stepper-content step="6">
          <ValidationObserver ref="obs6">
            <v-row><v-col></v-col></v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Verwarming" :rules="validationRules" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Verwarming" 
                    :items="heating"
                    item-text="name" 
                    item-value="value" 
                    v-model="location.installation.heating"
                    :error-messages="errors"
                   >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Verlichting" :rules="validationRules" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Verlichting" 
                    :items="lighting"
                    item-text="name" 
                    item-value="value" 
                    v-model="location.installation.lighting"
                    :error-messages="errors"
                    >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider name="Koeling" :rules="validationRules" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Koeling" 
                    :items="cooling"
                    item-text="name" 
                    item-value="value" 
                    v-model="location.installation.cooling"
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Bedekkingsgraad PV" :rules="validationRules" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined
                    persistent-placeholder 
                    label="Bedekkingsgraad PV" 
                    :items="pv"
                    item-text="name" 
                    item-value="value" 
                    v-model="location.installation.pv" 
                    :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                  <ValidationProvider name="Ventilatie" :rules="validationRules" v-slot="{ errors }">
                      <v-select 
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        label="Ventilatie"
                        :items="ventilation"
                        item-text="name"
                        item-value="value"
                        v-model="location.installation.ventilation"
                        :error-messages="errors"
                      >
                      </v-select>
                  </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Warm water" :rules="validationRules" v-slot="{ errors }">
                  <v-select 
                    hide-details="auto" 
                    outlined 
                    persistent-placeholder 
                    label="Warm water"
                    :items="domesticHotWater" 
                    item-text="name" 
                    item-value="value"
                    v-model="location.installation.domesticHotWater" 
                    :error-messages="errors"
                    >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 7" step="7"> Constructiegegevens </v-stepper-step>
        <v-stepper-content step="7">
          <ValidationObserver ref="obs7">
            <v-row>
              <v-container fluid class="boxed">
                <header>Soort</header>
                <div v-for="(item, index) in kinds" :key="item.key">
                    <v-divider v-if="index > 0"></v-divider>
                <ConstructionKindInput 
                 :kindKey="item.key"
                 :description="item.description"
                 :boundaryTypes="boundaryTypes"
                 :loadedKinds="loadedKinds"
                 :insulationCategories="insulationCategories"
                 :partData="getPartData(item.key)"
                 :onlyCategories="true"
                 :editMode="true"
                 @update-data="updatePart"
                ></ConstructionKindInput>
                </div>
              </v-container>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 8" step="8"> Energiedata </v-stepper-step>
        <v-stepper-content step="8">
          <ValidationObserver ref="obs8">
            <v-row>
              <v-col>
                <RegisterInput :registerTypes="registerTypes" :registerEnergyTypes="registerEnergyTypes" :registerUnitTypes="registerUnitTypes" @return-register-data="updateRegisters">
                </RegisterInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="nextStep()">Volgende stap</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 9" step="9"> Controle ingevoerde gegevens </v-stepper-step>
        <v-stepper-content step="9">
          <ValidationObserver ref="obs9">
            <v-container class="boxed">
              <header>Locatie wordt aangemaakt voor:</header>
              <v-row>
                <v-col>
                  <ul>
                    <li v-for="item in pandGoals" :key="item">
                      {{ translateGoals(item) }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed">
                <header>Gebouw Details</header>
                <v-row>
                  <v-col cols="6"><strong>Referentie:</strong></v-col>
                  <v-col cols="6">{{ location.custReference }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Omschrijving:</strong></v-col>
                  <v-col cols="6">{{ location.details.descr }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Geldig van:</strong></v-col>
                  <v-col cols="6">{{ location.valFrom }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Geldig tot:</strong></v-col>
                  <v-col cols="6">{{ location.valTill }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Gerbruiksoppervlakte (GO):</strong></v-col>
                  <v-col cols="6">{{ location.details.go }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Brutto vloeroppervlakte (BVO):</strong></v-col>
                  <v-col cols="6">{{ location.details.bvo }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Dakoppervlakte (DO):</strong></v-col>
                  <v-col cols="6">{{ location.details.do }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Type:</strong></v-col>
                  <v-col v-if="location.details.typ" cols="6">{{ locationTypes.find((x) => x.key === location.details.typ).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>ECM Functie:</strong></v-col>
                  <v-col v-if="location.details.ecmFuncId" cols="6">{{ ecmFunctions.find((x) => x.id === location.details.ecmFuncId).name }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>WEii Bouwbesluit:</strong></v-col>
                  <v-col v-if="location.weiiBldDecreeKey" cols="6">{{ weiiBldDecrees.find((x) => x.key === location.weiiBldDecreeKey).name }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>WEii Klasse:</strong></v-col>
                  <v-col v-if="location.details.weiiBldCatKey" cols="6">{{ weiiBldCategories.find((x) => x.key === location.details.weiiBldCatKey).name }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>EED:</strong></v-col>
                  <v-col v-if="location.details.eedTyp" cols="6">{{ eedTypes.find((x) => x.key === location.details.eedTyp).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Bouwdatum:</strong></v-col>
                  <v-col cols="6">{{ location.details.consDat }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Partij Referentie:</strong></v-col>
                  <v-col cols="6">{{ location.partyReference }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Partij Rol:</strong></v-col>
                  <v-col v-if="location.partyRole" cols="6">{{ partyRoles.find((x) => x.key === location.partyRole).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Installatie Groep:</strong></v-col>
                  <v-col v-if="location.installationGroupId" cols="6">{{ installationGroups.find((x) => x.id === location.installationGroupId).ref }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Gebouw Groep:</strong></v-col>
                  <v-col v-if="location.buildingGroupKey" cols="6">{{ constructionSourceTypes.find((x) => x.key === location.buildingGroupKey).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>ECM Groep:</strong></v-col>
                  <v-col v-if="location.ecmGroupId" cols="6">{{ measureGroups.find((x) => x.id === location.ecmGroupId).reference }}</v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>BAG</header>
                <v-data-table dense :headers="BAGheaders" hide-default-footer :items="bagSelected"> </v-data-table>
              </v-container>
              <v-container class="boxed">
                <header>Constructie</header>

                <div v-for="item in selectedParts" :key="item.key">
                  <v-row v-if="item.rcCategory != undefined">
                    <v-col cols="6"
                      ><strong>{{ getKindDescription(item.kindKey) }}</strong></v-col
                    >
                    <v-col cols="1">Rc:</v-col>
                    <v-col cols="3">{{ translateAssetConfig(insulationAndGlazing, item.rcCategory) }}</v-col>
                    <v-col cols="2">{{ Math.abs(item.rcValue) }}</v-col>
                  </v-row>
                  <v-row v-if="item.uCategory != undefined">
                    <v-col cols="6"
                      ><strong>{{ getKindDescription(item.kindKey) }}</strong></v-col
                    >
                    <v-col cols="1">U:</v-col>
                    <v-col cols="3">{{ translateAssetConfig(insulationAndGlazing, item.uCategory) }}</v-col>
                    <v-col cols="2">{{ Math.abs(item.uValue) }}</v-col>
                  </v-row>
                  <v-row v-if="item.gCategory != undefined">
                    <v-col cols="6"></v-col>
                    <v-col cols="1">G:</v-col>
                    <v-col cols="3">{{ translateAssetConfig(insulationAndGlazing, item.gCategory) }}</v-col>
                    <v-col cols="2">{{ Math.abs(item.gValue) }}</v-col>
                  </v-row>
                </div>
              </v-container>
              <v-container class="boxed">
                <header>Adres</header>
                <v-row>
                  <v-col cols="6"><strong>Omschrijving:</strong></v-col>
                  <v-col cols="6">{{ location.details.descr }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Postcode:</strong></v-col>
                  <v-col cols="6">{{ location.address.pc }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Stad:</strong></v-col>
                  <v-col cols="6">{{ location.address.city }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Land:</strong></v-col>
                  <v-col cols="6">{{ location.address.country }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Bron Type:</strong></v-col>
                  <v-col v-if="location.address.srcTyp && addressSourceTypesLoaded" cols="6">{{ addressSourceTypes.find((x) => x.key === location.address.srcTyp).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Bron Referentie:</strong></v-col>
                  <v-col cols="6">{{ location.address.srcRef }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Straat:</strong></v-col>
                  <v-col cols="6">{{ location.address.street }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Huisnummer:</strong></v-col>
                  <v-col cols="6">{{ location.address.hn }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Toevoeging:</strong></v-col>
                  <v-col cols="6">{{ location.address.hna }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Lengtegraad (lon):</strong></v-col>
                  <v-col cols="6">{{ location.address.lon }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Breedtegraad (lat):</strong></v-col>
                  <v-col cols="6">{{ location.address.lat }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Type:</strong></v-col>
                  <v-col v-if="location.address.typ" cols="6">{{ addressableObjectTypes.find((x) => x.key === location.address.typ).description }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Adres Type:</strong></v-col>
                  <v-col v-if="location.address.adrTyp" cols="6">{{ addressTypes.find((x) => x.key === location.address.adrTyp).description }}</v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Installatie</header>
                <v-row>
                  <v-col cols="6"><strong>Verwarming:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(heating, location.installation.heating) }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Koeling:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(cooling, location.installation.cooling) }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Ventilatie:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(ventilation, location.installation.ventilation) }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Verlichting:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(lighting, location.installation.lighting) }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Zonnepanelen:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(pv, location.installation.pv) }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Warm Water:</strong></v-col>
                  <v-col cols="6">{{ translateAssetConfig(domesticHotWater, location.installation.domesticHotWater) }}</v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Label</header>
                <v-row>
                  <v-col cols="6"><strong>Energie Label:</strong></v-col>
                  <v-col cols="6">{{ location.label.energyLabel }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Energie Index:</strong></v-col>
                  <v-col cols="6">{{ location.label.energyIndex }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"><strong>Energiebron:</strong></v-col>
                  <v-col cols="6">{{ location.label.sourceKey }}</v-col>
                </v-row>
              </v-container>
            </masonry>
            <v-container fluid class="boxed">
              <header>Energiedata</header>
              <v-data-table dense :headers="Registerheaders" hide-default-footer :items="selectedRegisters">
                <template v-slot:[`item.id`]="{ item }">
                  <v-container fluid>
                    <template v-if="item.usages.length > 0">
                      <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="4"><strong>Jaar</strong></v-col>
                        <v-col cols="2"><strong>Waarde</strong></v-col>
                      </v-row>
                      <v-row v-for="usage in _.sortBy(item.usages, 'year')" :key="usage.key">
                        <v-col cols="1"></v-col>
                        <v-col cols="4">{{ usage.year }}</v-col>
                        <v-col cols="2">{{ usage.value }}</v-col>
                      </v-row>
                    </template>
                    <v-row v-else>
                      <v-col cols="1"></v-col>
                      <v-col cols="6">Geen verbruiken opgegeven</v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-data-table>
            </v-container>
            <v-row v-if="saving">
              <v-layout justify-center align-center>
                <v-progress-circular indeterminate :size="70" :width="7" color="primary"></v-progress-circular>
              </v-layout>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn color="normal" @click="previousStep()" :disabled="isSaving">Vorige stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="save()" :disabled="isSaving" >Opslaan</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="normal" @click="cancel()" :disabled="isSaving">Annuleren</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-stepper-content>
      </v-stepper>
    </v-container>
  </v-container>
</template>
