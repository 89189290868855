var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "1" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h4", [_vm._v("Meters")]),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { small: "", dark: "" },
                  on: { click: _setup.addRegister },
                },
                [
                  _c("v-icon", { attrs: { small: "", dark: "", center: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        _setup.ValidationObserver,
        { ref: "obsRegister" },
        [
          _c(
            "v-form",
            [
              _c("v-data-table", {
                staticClass: "no-stripes",
                attrs: {
                  dense: "",
                  headers: _setup.headers,
                  "hide-default-footer": "",
                  items: _setup.registers,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.registerType`,
                      fn: function ({ item }) {
                        return [
                          _c(_setup.ValidationProvider, {
                            attrs: { name: "Type", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-select", {
                                        class: {
                                          noBorders: !_setup.inEdit(item.id),
                                        },
                                        attrs: {
                                          "hide-details": "auto",
                                          items: _setup.props.registerTypes,
                                          "item-text": "description",
                                          "item-value": "key",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          "error-messages": errors,
                                          outlined: _setup.inEdit(item.id),
                                          readonly: !_setup.inEdit(item.id),
                                        },
                                        model: {
                                          value: item.registerTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "registerTypeId",
                                              $$v
                                            )
                                          },
                                          expression: "item.registerTypeId",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.registerEnergyType`,
                      fn: function ({ item }) {
                        return [
                          _c(_setup.ValidationProvider, {
                            attrs: {
                              name: "RegisterEnergyType",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-select", {
                                        class: {
                                          noBorders: !_setup.inEdit(item.id),
                                        },
                                        attrs: {
                                          "hide-details": "auto",
                                          items:
                                            _setup.props.registerEnergyTypes,
                                          "item-text": "description",
                                          "item-value": "key",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          "error-messages": errors,
                                          outlined: _setup.inEdit(item.id),
                                          readonly: !_setup.inEdit(item.id),
                                        },
                                        model: {
                                          value: item.registerEnergyTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "registerEnergyTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.registerEnergyTypeId",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.registerUnitType`,
                      fn: function ({ item }) {
                        return [
                          _c(_setup.ValidationProvider, {
                            attrs: { name: "UnitType", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-select", {
                                        class: {
                                          noBorders: !_setup.inEdit(item.id),
                                        },
                                        attrs: {
                                          "hide-details": "auto",
                                          items: _setup.props.registerUnitTypes,
                                          "item-text": "description",
                                          "item-value": "key",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          "error-messages": errors,
                                          outlined: _setup.inEdit(item.id),
                                          readonly: !_setup.inEdit(item.id),
                                        },
                                        model: {
                                          value: item.registerUnitTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "registerUnitTypeId",
                                              $$v
                                            )
                                          },
                                          expression: "item.registerUnitTypeId",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.ean`,
                      fn: function ({ item }) {
                        return [
                          !_setup.inEdit(item.id)
                            ? _c("span", [_vm._v(" " + _vm._s(item.ean) + " ")])
                            : _c(_setup.ValidationProvider, {
                                attrs: { name: "Ean", rules: "min:18|max:18" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.inEdit(
                                                item.id
                                              ),
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              outlined: _setup.inEdit(item.id),
                                              "persistent-placeholder": "",
                                              dense: "",
                                              "error-messages": errors,
                                              readonly: !_setup.inEdit(item.id),
                                            },
                                            model: {
                                              value: item.ean,
                                              callback: function ($$v) {
                                                _vm.$set(item, "ean", $$v)
                                              },
                                              expression: "item.ean",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  disabled:
                                                    _setup.inEdit("any"),
                                                  icon: "",
                                                  small: "",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.editRow(
                                                      item.id,
                                                      true,
                                                      false,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-pencil ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Bewerken")])]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.confirm`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled: !_setup.inEdit(
                                                        item.id
                                                      ),
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.editRow(
                                                          item.id,
                                                          false,
                                                          true,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      " mdi-checkbox-marked-circle "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Opslaan")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: `item.cancel`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled: !_setup.inEdit(
                                                        item.id
                                                      ),
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.editRow(
                                                          item.id,
                                                          false,
                                                          false,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-cancel ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Annuleren")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: `item.delete`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled: !_setup.inEdit(
                                                        item.id
                                                      ),
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.editRow(
                                                          item.id,
                                                          false,
                                                          false,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Verwijderen")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: `item.id`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { "white-space": "pre-wrap" } },
                            [
                              _c(_setup.RegisterUsageYearly, {
                                attrs: { registerId: item.id.toString() },
                                on: {
                                  "return-register-usage-data":
                                    _setup.updateRegisterUsages,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }